import React, { useRef, useEffect, useState } from 'react';
import { Card, Button, Table, Collapse } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { strapiUrl } from '../../../utils/constants';
import DownloadFile from '../Forms/Elements/DownloadFile';
import { getReportRoundYear } from '../../../utils/getReportRoundYear';

import { calculateColumnWidths } from '../../../utils/calculateColumnWidths';
import { calculateDynamicStyle } from '../../../utils/calculateDynamicStyle';

const NominationCard = ({
  nomination,
  candidates,
  refereeReports,
  sectionalCommittees,
  activeFellows,
  nonAasReferees,
  declineReasons,
  refereeStatuses,
  handleSelectClick,
  toggleCollapse,
  open,
  setNominationId,
  userDetails,
}) => {
  const filteredReports = refereeReports.filter(
    (report) =>
      report.attributes.nominationId === nomination.id &&
      !report.attributes.refereeArchived,
  );

  const cellRefs = useRef([]);
  const [columnWidths, setColumnWidths] = useState({});

  useEffect(() => {
    setColumnWidths(calculateColumnWidths(cellRefs.current, 150, 250));
  }, [refereeReports.length]);

  return (
    <Card key={nomination.id} className="card-two mb-xl-5 table-responsive">
      <Card.Body>
        <Card.Title className="mb-xl-4">
          <div className="row align-items-center">
            <div className="col">
              {candidates[nomination.attributes.candidateId]}
              {nomination.attributes.sectionalCommitteeId !== null &&
                nomination.attributes.overlapCommittee &&
                nomination.attributes.overlapSectionalCommitteeId !== null && (
                  <span className="badge badge-primary m-1">
                    <i>
                      {
                        sectionalCommittees[
                          nomination.attributes.sectionalCommitteeId
                        ]
                      }{' '}
                      Overlaps with{' '}
                      {
                        sectionalCommittees[
                          nomination.attributes.overlapSectionalCommitteeId
                        ]
                      }
                    </i>
                  </span>
                )}{' '}
              <Link
                to="/form-view"
                onClick={() => setNominationId(nomination.id)}
              >
                <span title="View">
                  <i className="bi bi-eye view-button" />
                </span>
              </Link>
            </div>
            <div className="col-auto">
              <Button
                variant="link"
                onClick={() => toggleCollapse(nomination.id)}
                aria-expanded={open[nomination.id] || false}
              >
                {open[nomination.id] ? 'Hide Referees' : 'Show Referees'}
              </Button>
            </div>
          </div>
        </Card.Title>
        <Collapse in={open[nomination.id]}>
          <div>
            <Table bordered hover striped className="dataTable">
              <thead className="thead-dark">
                <tr>
                  <th>
                    <b>Name</b>
                  </th>
                  <th>
                    <b>Status</b>
                  </th>
                  <th>
                    <b>Type</b>
                  </th>
                  <th>
                    <b>Round</b>
                  </th>
                  <th aria-label="Actions">
                    <b />
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredReports.map((referee, index) => {
                  const reportYear = getReportRoundYear(referee.attributes);
                  const candidateName =
                    candidates[nomination.attributes.candidateId]?.replace(
                      /\s/g,
                      '',
                    ) || '';
                  const refereeName = referee.attributes.refereeFellowId
                    ? activeFellows[referee.attributes.refereeFellowId]
                    : nonAasReferees[referee.attributes.nonAasRefereeId];

                  let reason = '';
                  const {
                    refereeStatusId,
                    declineReasonId,
                    declineReasonOther,
                    extensionReason,
                    extensionReasonApprovalNote,
                  } = referee.attributes;

                  if (refereeStatusId === 3) {
                    reason =
                      declineReasonId === 5
                        ? declineReasonOther
                        : declineReasons[declineReasonId];
                  } else if (refereeStatusId === 5) {
                    reason = extensionReason;
                  } else if (refereeStatusId === 6) {
                    reason = extensionReasonApprovalNote;
                  }

                  return (
                    <tr key={index}>
                      <td
                        ref={(el) => (cellRefs.current[0] = el)}
                        style={calculateDynamicStyle(columnWidths[0])}
                      >
                        {refereeName}
                      </td>
                      <td
                        ref={(el) => (cellRefs.current[1] = el)}
                        style={calculateDynamicStyle(columnWidths[1])}
                      >
                        {reason ? (
                          <>
                            <span>
                              <b>
                                {
                                  refereeStatuses[
                                    referee.attributes.refereeStatusId
                                  ]
                                }
                                {': '}
                              </b>
                            </span>
                            {refereeStatusId === 6 ? (
                              <>
                                <br />
                                <span>Reason: {extensionReason}</span>
                                <br />
                                <span>
                                  Approval Note: {extensionReasonApprovalNote}
                                </span>
                              </>
                            ) : (
                              <span>{reason}</span>
                            )}
                          </>
                        ) : (
                          <span>
                            {
                              refereeStatuses[
                                referee.attributes.refereeStatusId
                              ]
                            }
                          </span>
                        )}
                      </td>
                      <td
                        ref={(el) => (cellRefs.current[2] = el)}
                        style={calculateDynamicStyle(columnWidths[2])}
                      >
                        {referee.attributes.independentAssessor
                          ? 'Independent Assessor'
                          : 'Referee'}
                      </td>
                      <td
                        ref={(el) => (cellRefs.current[3] = el)}
                        style={calculateDynamicStyle(columnWidths[3])}
                      >
                        {reportYear}
                      </td>
                      <td
                        className="text-center"
                        ref={(el) => (cellRefs.current[4] = el)}
                        style={calculateDynamicStyle(columnWidths[4])}
                      >
                        {referee.attributes.report.data !== null && (
                          <DownloadFile
                            text={`Report Uploaded Year: ${reportYear}`}
                            url={`${strapiUrl}${referee.attributes.report.data.attributes.url}`}
                            name={`${candidateName}${refereeName}`}
                            buttonClassName="action-button-two"
                            iconClassName="download-button"
                            showFileName={false}
                            showText={false}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Collapse>
        <div className="row justify-content-end mt-xl-4">
          {userDetails &&
            (userDetails.roles.includes('committee-chair') ||
              userDetails.roles.includes('admin')) && (
              <div className="col-auto">
                {nomination.attributes.nominationStatusTypeId === 3 ? (
                  <Button variant="success" disabled>
                    Shortlisted
                  </Button>
                ) : (
                  <Button
                    variant="success"
                    onClick={() =>
                      handleSelectClick(
                        nomination.id,
                        candidates[nomination.attributes.candidateId],
                      )
                    }
                  >
                    Shortlist
                  </Button>
                )}
              </div>
            )}
        </div>
      </Card.Body>
    </Card>
  );
};

NominationCard.propTypes = {
  nomination: PropTypes.object.isRequired,
  candidates: PropTypes.object.isRequired,
  refereeReports: PropTypes.array.isRequired,
  sectionalCommittees: PropTypes.object.isRequired,
  activeFellows: PropTypes.object.isRequired,
  nonAasReferees: PropTypes.object.isRequired,
  declineReasons: PropTypes.object.isRequired,
  refereeStatuses: PropTypes.object.isRequired,
  handleSelectClick: PropTypes.func.isRequired,
  toggleCollapse: PropTypes.func.isRequired,
  open: PropTypes.object.isRequired,
  setNominationId: PropTypes.func.isRequired,
  userDetails: PropTypes.object,
};

export default NominationCard;
